import getYouTubeID from 'get-youtube-id';
import { getWindow } from '@r-client/shared/util/core';
import { E_VIDEO_TYPE } from './types';
export var getDevicePixelRatio = function getDevicePixelRatio() {
  var wnd = getWindow();

  if (!wnd) {
    return undefined;
  }

  return wnd.devicePixelRatio;
};
export var is2xDisplay = function is2xDisplay() {
  return getDevicePixelRatio() === 2;
};
export function getYouTubePreview(url) {
  var id = getYouTubeID(url);
  var youTubeUrl = 'https://img.youtube.com/vi/';

  if (!id && url.length === 11) {
    id = url;
  }

  return {
    "default": "".concat(youTubeUrl).concat(id, "/default.jpg"),
    // 120x90 - always present
    medium: "".concat(youTubeUrl).concat(id, "/mqdefault.jpg"),
    // 320x180
    high: "".concat(youTubeUrl).concat(id, "/hqdefault.jpg"),
    // 480x360 - always present
    sd: "".concat(youTubeUrl).concat(id, "/sddefault.jpg"),
    // 640x480 - not always present
    max: "".concat(youTubeUrl).concat(id, "/maxresdefault.jpg") // 1280x720 - not always present

  };
}
export var isYouTubeVideoHandler = function isYouTubeVideoHandler(media) {
  return (media === null || media === void 0 ? void 0 : media.type) === E_VIDEO_TYPE.youTubeUrl;
};