export var E_VIDEO_TYPE;

(function (E_VIDEO_TYPE) {
  E_VIDEO_TYPE["video"] = "video";
  E_VIDEO_TYPE["youTubeUrl"] = "youTubeUrl";
})(E_VIDEO_TYPE || (E_VIDEO_TYPE = {}));

export var E_VIDEO_ASPECT_RATIO;

(function (E_VIDEO_ASPECT_RATIO) {
  E_VIDEO_ASPECT_RATIO["standard"] = "standard";
  E_VIDEO_ASPECT_RATIO["widescreen"] = "widescreen";
  E_VIDEO_ASPECT_RATIO["cinematic"] = "cinematic";
})(E_VIDEO_ASPECT_RATIO || (E_VIDEO_ASPECT_RATIO = {}));